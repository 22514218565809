<template>
	<h3 class="col-12 checked-date">
		{{ checkedDate }}
	</h3>
</template>

<script>
export default {
	props: {
		selectedDay: {
			type: String,
			required: true,
		},
		activeWeek: {
			type: String,
			required: true,
		},
		firstWeekMondayTimestamp: {
			type: Number,
			required: true,
		},
	},
	computed: {
		checkedDate() {
			const vm = this,
				days = 1000 * 60 * 60 * 24,
				// title = vm.dayList.find(({ value }) => value === vm.selectedDay).title,
				dayToAdd =
					vm.activeWeek === '1'
						? (Number(vm.selectedDay === '0' ? '7' : vm.selectedDay) - 1) * days
						: (7 + Number(vm.selectedDay === '0' ? '7' : vm.selectedDay) - 1) * days,
				date = new Date(vm.firstWeekMondayTimestamp + dayToAdd).toLocaleDateString('ru-RU', {
					day: 'numeric',
					month: 'long',
					weekday: 'long',
				});
			return `${date}`;
		},
	},
};
</script>
